/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.

/*
 * EXPORTS
 */
export default gql`
  query VendorReadQuery($take: PositiveInt!, $skip: Int!) {
    VendorRead(take: $take, skip: $skip, returnDeliveriesOfTrunk: false) {
      id,
      displayName,
      message,
      status,
      VendorAccount {
        id,
        displayName
      }
    }
  }
`
