/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import Moment from 'moment' // Npm: moment.js library.
import JoiBrowser from 'joi-browser' // Npm: Joi for frontend validation.
import _ from 'underscore' // Npm: underscore library.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { useMutation, useQuery } from '@apollo/client' // Npm: Apollo client.
import { HiArrowSmallRight, HiCheckCircle, HiCurrencyEuro, HiDocumentText, HiTrash } from 'react-icons/hi2' // Npm: React icons.
import {
  Button,
  Flex,
  FormControl,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  useBreakpointValue
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import TableSpinner from 'components/TableSpinner'
import PaginationWithPageInformation from 'components/PaginationWithPageInformation'
import DateRangePicker from 'components/DateRangePicker'
import DownloadToExcel from 'components/DownloadToExcel'
import CustomerSelector from 'components/CustomerSelector'
import EntitySelector from 'components/EntitySelector'
import { MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import CustomerInvoiceCreateMutation from './__mutation__/index.customerInvoice.create.mutation'
import CustomerInvoiceDeleteMutation from './__mutation__/index.customerInvoice.delete.mutation'
import CustomerInvoiceReadQuery from './__query__/index.customerInvoice.read.query'


/*
 * STYLES
 */
import { cellStyle, headerStyle, rowStyle } from './index.style'


/*
 * OBJECTS
 */
const Index = () => {
  // Const assignment.
  const _skipDifference = 8

  // Hook assignment.
  const [dateRange, setDateRange] = React.useState({
    'selection': {
      'startDate': Object.DateRangeProvider()?.startDate,
      'endDate': Object.DateRangeProvider()?.endDate,
      'key': 'selection'
    }
  })
  const [customerType, setCustomerType] = React.useState(void 0)
  const [skipPage, setSkipPage] = React.useState(0)
  const [customerId, setCustomerId] = React.useState('')
  const [entityId, setEntityId] = React.useState('')
  const [invoiceDeleteInProgress, setInvoiceDeleteInProgress] = React.useState([])
  const [error, setError] = React.useState('')
  const [skipDifference, setSkipDifference] = React.useState(_skipDifference)
  const [MutationCustomerInvoiceCreate, MutationCustomerInvoiceCreateResponse] = useMutation(CustomerInvoiceCreateMutation)
  const [MutationCustomerInvoiceDelete] = useMutation(CustomerInvoiceDeleteMutation)
  const _tableHeaderHeightRef = React.useRef(0)
  const _QueryCustomerInvoiceRead = useQuery(CustomerInvoiceReadQuery, {
    'variables': {
      customerId,
      'skip': skipPage * skipDifference,
      'take': skipDifference
    },
    'fetchPolicy': Object.React.App.fetchPolicy,
    'pollInterval': Object.React.App.pollInterval
  })
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  // Object assignment.
  const _DownloadCustomerInvoice = __url => {
    // Const assignment.
    const a = document.createElement('a')

    // Object assignment.
    a.href = __url
    a.download = __url.split('/').pop()

    /*
     * Append and remove child.
     * with fake click so that file can be downloaded.
     */
    document.body.appendChild(a)

    // Fake click.
    a.click()

    // Remove child.
    document.body.removeChild(a)
  }
  const _GenerateCustomerInvoice = async () => {
    // Const assignment.
    const _JoiSchema = JoiBrowser.object({
      'customerId': JoiBrowser.string().required(),
      'entityId': JoiBrowser.string().required()
    })

    // Validate form data.
    const _JoiSchemaValidate = _JoiSchema.validate({ customerId, entityId })

    // If error exists then report failure.
    if (_JoiSchemaValidate.error) return setError(_JoiSchemaValidate.error?.message)

    // Execute update mutation.
    const _MutationCustomerInvoiceCreate = await MutationCustomerInvoiceCreate({
      'variables': { customerId, entityId, 'smsStartDate': dateRange?.selection?.startDate, 'smsEndDate': dateRange?.selection?.endDate }
    })

    // If mutation caught an exception then report failure.
    if (_MutationCustomerInvoiceCreate instanceof Error) return _MutationCustomerInvoiceCreate

    // Style Guide.
    toast(_MutationCustomerInvoiceCreate?.data?.CustomerInvoiceCreate?.message)

    // Refetch customerInvoice updates.
    return _QueryCustomerInvoiceRead.refetch()
  }
  const _DeleteCustomerInvoice = async __id => {
    // Const assignment.
    const _JoiSchema = JoiBrowser.object({ 'id': JoiBrowser.string().required() })

    // Validate form data.
    const _JoiSchemaValidate = _JoiSchema.validate({ 'id': __id })

    // If error exists then report failure.
    if (_JoiSchemaValidate.error) return setError(_JoiSchemaValidate.error?.message)

    // Update state.
    setInvoiceDeleteInProgress([...invoiceDeleteInProgress, __id])

    // Execute update mutation.
    const _MutationCustomerInvoiceDelete = await MutationCustomerInvoiceDelete({ 'variables': { 'customerInvoiceId': __id } })

    // Update state.
    setInvoiceDeleteInProgress(invoiceDeleteInProgress.filter(j => j !== __id))

    // If mutation caught an exception then report failure.
    if (_MutationCustomerInvoiceDelete instanceof Error) return _MutationCustomerInvoiceDelete

    // Style Guide.
    toast(_MutationCustomerInvoiceDelete?.data?.CustomerInvoiceDelete?.message)

    // Refetch customerInvoice updates.
    return _QueryCustomerInvoiceRead.refetch()
  }

  // Event handler.
  React.useEffect(() => {
    // Clear error.
    setError('')
  }, [customerId, entityId])

  // Return component.
  return (
    <>
      <Flex
        display='flex'
        flex={1}
        flexDirection='column'
        gap={_isCurrentViewMobile ? '12px' : '22px'}
        bg='white'
        borderRadius='20px'
        p={_isCurrentViewMobile ? '12px' : '22px'}>
        <Flex w='100%' alignItems='end' justifyContent='space-between'>
          <Flex
            w='100%'
            flexDir={{ 'base': 'column', 'md': 'row' }}
            justifyContent='space-between'
            gap='12px'
            alignItems='center'>
            <Flex flex={0.6} flexDir='column' gap='22px'>
              <Flex flexDir='column' gap='12px'>
                <Flex color='brand.500' gap='6px' alignItems='center'>
                  <HiCurrencyEuro size='31px' />
                  <Text fontSize={31} fontWeight={300}>
                    Customer Invoice
                  </Text>
                </Flex>
                <Text w={{ 'base': '100%', 'md': '50%' }} fontSize={18} color='gray.500'>
                  Generate invoices for your customer based on time frame and
                  entity of your preference.
                </Text>
                <Text as='h3' fontSize={17} fontWeight={500} display='flex' alignItems='center' color='brand.500' gap='6px'>Select Billing date and other options.<HiArrowSmallRight /></Text>
              </Flex>
            </Flex>
            <Flex
              flex={0.4}
              zIndex={100}
              flexDir='row'
              gap='12px'
              justifyContent='flex-end'>
              <Flex flexDir='column' gap='12px'>
                <Flex flex={0.6} flexDir='row' gap='12px' justify='end' align='end'>
                  <Flex overflow='hidden' flexDir='column' w='50%' gap='12px'>
                    <DateRangePicker asFatButton startDate={dateRange?.selection?.startDate} endDate={dateRange?.selection?.endDate} fontSize='13px' height='max-content' gap='6px' setSelectDate={setDateRange} />
                  </Flex>
                  <Flex w='50%'>
                    <FormControl>
                      <EntitySelector
                        placeholder='Select Entity'
                        isInvalid={error?.includes?.('entityId')}
                        onChange={j => setEntityId(j.target.value.split?.('(')?.[1]?.split?.(')')?.[0])}
                      />
                    </FormControl>
                  </Flex>
                </Flex>
                <Flex mt='0' width='100%' gap='12px'>
                  <Flex mt='0' width='100%' flex={1}>
                    <MemoizedSelect
                      placeholder='Select Account Type'
                      className='filter searchInput'
                      onChange={e => setCustomerType(_.isEmpty(e?.target?.value) ? void 0 : e?.target?.value)}
                      options={_.without(Object.React.App.enums.CUSTOMER_TYPE.enums?.map(i => i?.key), 'TERMINATION')}
                      data={customerType}
                    />
                  </Flex>
                  <Flex width='100%' flex={1}>
                    <CustomerSelector
                      customerType={customerType}
                      placeholder='Select Customer'
                      isInvalid={error?.includes?.('customerId')}
                      onChange={j => setCustomerId(j.target.value?.split?.('(')?.[1]?.split?.(')')?.[0])}
                    />
                  </Flex>
                </Flex>
              </Flex>
              <Flex>
                <Button
                  w='max-content'
                  bg='brand.500'
                  h='100%'
                  flexDir='column-reverse'
                  color='gray.50'
                  borderRadius={22}
                  boxShadow='0px 4px 4px var(--chakra-colors-brand-100)'
                  _hover={{ 'bg': 'brand.600' }}
                  _active={{ 'bg': 'brand.600' }}
                  onClick={_GenerateCustomerInvoice}>
                  <Text fontWeight={500} fontSize='md' mt={2}>
                    Create Invoice
                  </Text>
                  {MutationCustomerInvoiceCreateResponse.loading ? (
                    <Spinner size='sm' color='#c1a9f4' />
                  ) : (
                    <HiCheckCircle size={26} />
                  )}
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <TableContainer
          flex={1}
          display='flex'
          overflowY='scroll'
          borderRadius='15px'
          minH='220px'
          bg='transparent'
          outline='1px solid #C5CFE8'>
          <Table variant='simple' size='sm'>
            <Thead
              ref={_tableHeaderHeightRef}
              color='rgba(141, 151, 182, 1)'
              fontStyle='DM Sans'
              fontWeight='500'
              fontSize='18px'
              lineHeight='24px'>
              <Tr
                style={{
                  ...headerStyle,
                  'position': 'sticky',
                  'top': '0',
                  'zIndex': '1'
                }}>
                <Td
                  py='10px'
                  textAlign='center'
                  px='0px'
                  style={rowStyle}
                  borderRight='1px solid rgba(216, 227, 252, 1)'>
                  <Text>S.No</Text>
                </Td>
                <Td
                  py='10px'
                  textAlign='center'
                  px='0px'
                  style={rowStyle}
                  borderRight='1px solid rgba(216, 227, 252, 1)'>
                  <Text>Invoice Number</Text>
                </Td>
                <Td
                  style={rowStyle}
                  borderRight='1px solid rgba(216, 227, 252, 1)'>
                  Customer
                </Td>
                <Td
                  style={rowStyle}
                  borderRight='1px solid rgba(216, 227, 252, 1)'>
                  Entity Name
                </Td>
                <Td
                  style={rowStyle}
                  borderRight='1px solid rgba(216, 227, 252, 1)'>
                  <Text>Billing Start Date</Text>
                </Td>
                <Td
                  style={rowStyle}
                  borderRight='1px solid rgba(216, 227, 252, 1)'>
                  <Text>Billing End Date</Text>
                </Td>
                <Td
                  style={rowStyle}
                  borderRight='1px solid rgba(216, 227, 252, 1)'>
                  <Text>Amount</Text>
                </Td>
                <Td
                  style={rowStyle}
                  borderRight='1px solid rgba(216, 227, 252, 1)'>
                  <Text>Traffic Details</Text>
                </Td>
                <Td
                  style={rowStyle}
                  borderRight='1px solid rgba(216, 227, 252, 1)'>
                  <Text>Invoice</Text>
                </Td>
                <Td
                  style={rowStyle}>
                  Delete
                </Td>
              </Tr>
            </Thead>
            <Tbody style={cellStyle}>
              {_QueryCustomerInvoiceRead.loading ? (
                <TableSpinner
                  isLoading={true}
                  chopHeightFromHundredPercentage={
                    _tableHeaderHeightRef?.current?.clientHeight
                  }
                />
              ) : 0 === _QueryCustomerInvoiceRead.data?.CustomerInvoiceRead?.length || !_.every(_.pluck(_QueryCustomerInvoiceRead.data?.CustomerInvoiceRead, 'status'), i => 'READ_SUCCESSFUL' === i) ? (
                <TableSpinner
                  isLoading={false}
                  isEmpty={true}
                  chopHeightFromHundredPercentage={
                    _tableHeaderHeightRef?.current?.clientHeight
                  }
                />
              ) : (
                <>
                  {_QueryCustomerInvoiceRead.data?.CustomerInvoiceRead?.map(
                    (
                      {
                        id,
                        billingStartDate,
                        billingEndDate,
                        invoiceStoredAt,
                        detailedInvoice,
                        amount,
                        Customer,
                        Entity
                      },
                      __index
                    ) => (
                      <Tr key={String.random(9)}>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {' '}
                          {`${__index + (skipDifference * skipPage) + 1}.`}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {' '}
                          {id}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {' '}
                          {Customer?.Company?.displayName}
                        </Td>
                        <Td
                          style={rowStyle}
                          width='100%'
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {' '}
                          {Entity?.displayName}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {Moment(billingStartDate).utc().format('YYYY-MM-DD')}{' '}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {Moment(billingEndDate).utc().format('YYYY-MM-DD')}{' '}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {' '}
                          {amount}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {' '}
                          <DownloadToExcel
                            onlyShowIcon={true}
                            bg='transparent'
                            _hover={{ 'bg': 'none' }}
                            _active={{ 'bg': 'none' }}
                            cellsData={_.isEmpty(detailedInvoice) ? [] : _.compact(_.flatten(Object.values(detailedInvoice)?.map(j => Object.values(j)?.map(r => Object.values(r)))))
                              .filter((__d) =>
                                // Filter out rows with undefined or null values in critical fields
                                __d?.deliveryDate && __d?.totalSmsCount > 0 && __d?.mnc
                              )
                              .sort((a, b) => {
                                // First sort by date
                                const dateCompare = new Date(a.deliveryDate) - new Date(b.deliveryDate);
                                if (dateCompare !== 0) return dateCompare;
                                // If dates are same, sort by totalSmsCount
                                return Number(b.totalSmsCount) - Number(a.totalSmsCount);

                              })
                              .map((__d) => ({
                                'Period': __d?.deliveryDate?.split('T')[0] || '-',
                                'Account Name': __d?.customerAccount ?? '-',
                                'Mcc': __d?.mcc ?? '-',
                                'Mnc': __d?.mnc,
                                'Sms Count': __d?.totalSmsCount ?? '-',
                                'Unit Cost': __d?.rate ?? '-',
                                'Total Cost': __d?.totalCost ?? '-',
                                'Currency': __d?.currency ?? '-'
                              }))}
                            headersData={[
                              'Period',
                              'Account Name',
                              'Mcc',
                              'Mnc',
                              'Sms Count',
                              'Unit Cost',
                              'Total Cost',
                              'Currency'
                            ].map(i => ({ 'key': i, 'label': i }))}
                          />
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {' '}
                          <Button
                            color='brand.600'
                            bg='none'
                            _hover={{ 'bg': 'none' }}
                            _active={{ 'bg': 'none' }}
                            onClick={() => _DownloadCustomerInvoice(invoiceStoredAt?.path)}>
                            <HiDocumentText size={17} />
                          </Button>
                        </Td>
                        <Td
                          style={rowStyle}>
                          {' '}
                          <Button
                            color='red'
                            bg='none'
                            _hover={{ 'bg': 'none' }}
                            _active={{ 'bg': 'none' }}
                            onClick={() => _DeleteCustomerInvoice(id)}>
                            {invoiceDeleteInProgress?.includes(id) ? <Spinner size='sm' /> : <HiTrash size={17} />}
                          </Button>
                        </Td>
                      </Tr>
                    )
                  )}
                </>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Flex>
      <PaginationWithPageInformation
        skipPage={skipPage}
        skipDifference={_skipDifference}
        setSkipDifference={setSkipDifference}
        itemsPerPage={_skipDifference}
        totalCount={_.pluck(_QueryCustomerInvoiceRead?.data?.CustomerInvoiceRead, '_totalCount')?.[0]}
        onPageChange={setSkipPage}
      />
    </>
  )
}
Index.propTypes = {}


/*
 * EXPORTS
 */
export default Index
